<template>
  <v-container>
    <div>Hamochi | Log In | Get Started for Free!</div>
    <div>Banner</div>
    <div>Empowering Young Minds</div>
    <div>Discover fun, interactive, and effecive learning with Hamochi.</div>
    <div>Get started for free</div>
    <div>Feature</div>
    <div>Event, Math, English, Art</div>
    <div>(Need a page for each features)</div>
    <div>Why Choose Hamochi?</div>
    <div>Schools</div>
    <div>Pricing Plans</div>
    <div>Demo</div>
    <div>Testimonial</div>
    <div>Sponsor</div>
    <div>Footer</div>
    <div>Question Bank</div>
    <div>Social Media, Shopee, Youtube</div>
    <div>(Need a page for school, consider to use old layout)</div>
    <div>(Need a page for sponsor)</div>
    <div>(Need a page for event)</div>
    <SlideCopyright/>
  </v-container>
</template>

<script>
// import SlideClient from "@/components/views/PageMainHome/V3SlideClient.vue";
// import SlideContact from "@/components/views/PageMainHome/SlideContact.vue";
import SlideCopyright from "@/components/views/PageMainHome/V3SlideCopyright.vue";
// import SlideEvent from "@/components/views/PageMainHome/V3SlideEvent.vue";
// import SlideFAQ from "@/components/views/PageMainHome/V3SlideFAQ.vue";
// import SlideFeature from "@/components/views/PageMainHome/V3SlideFeature.vue";
// import SlideGame from "@/components/views/PageMainHome/SlideGame.vue";
// import SlideLogin from "@/components/views/PageMainHome/V3SlideLogin.vue";
// import SlidePerformance from "@/components/views/PageMainHome/V3SlidePerformance.vue";
// import SlidePitch from "@/components/views/PageMainHome/V3SlidePitch.vue";
// import SlidePoint from "@/components/views/PageMainHome/V3SlidePoint.vue";
// import SlideSocial from "@/components/views/PageMainHome/SlideSocial.vue";
// import SlideSyllabus from "@/components/views/PageMainHome/SlideSyllabus.vue";
// import SlideTestimony from "@/components/views/PageMainHome/SlideTestimony.vue";
// import SlideTestimonyParent from "@/components/views/PageMainHome/V3SlideTestimonyParent.vue";
// import SlideVideo from "@/components/views/PageMainHome/V3SlideVideo.vue";
export default {
  components: {
    // SlideClient,
    // SlideContact,
    SlideCopyright,
    // SlideEvent,
    // SlideFAQ,
    // SlideFeature,
    // SlideGame,
    // SlideLogin,
    // SlidePerformance,
    // SlidePitch,
    // SlidePoint,
    // SlideSocial,
    // SlideSyllabus,
    // SlideTestimony,
    // SlideTestimonyParent,
    // SlideVideo,
  },
  data: () => ({
    //
  }),
};
</script>
